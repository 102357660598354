import { ERedirectActions } from 'shared/enums/redirectActions.enum';
import { useUserStore } from 'store/user.store';
import { useInternalStore } from 'shared/store/internalStore.store';
import { CookieManager } from 'shared/utils/cookieManager.util';
import { RequestHelper } from 'shared/utils/requestHelper.util';

export default defineNuxtRouteMiddleware((to) => {
  const runtimeConfig = useRuntimeConfig();
  const redirectConfig = {
    path: `/redirect/${ERedirectActions.Auth}`,
    query: {
      to: to.path,
    },
    replace: true,
  };

  if (process.server) {
    const event = useRequestEvent();
    const accessToken = CookieManager.getCookieValueByName(
      event?.node?.req.headers.cookie || '',
      runtimeConfig?.public?.accessTokenName || '',
    );
    const refreshToken = CookieManager.getCookieValueByName(
      event?.node?.req.headers.cookie || '',
      runtimeConfig?.public?.refreshTokenName || '',
    );
    const isInternalUser = RequestHelper.hasXInternalNetwork(event?.node?.req?.headers || {});

    if (!refreshToken && !accessToken && !isInternalUser) {
      return navigateTo(redirectConfig);
    }

    return;
  }

  if (process.client) {
    const userStore = useUserStore();
    const internalStore = useInternalStore();
    if (!userStore.isUserLoggedIn && !internalStore.getIsInternalUser) {
      return navigateTo(redirectConfig);
    }
  }
});
